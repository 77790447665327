import Vue from 'vue'
import App from './views/App'
import router from './router'
import store from './store'

import 'normalize.css/normalize.css'

Vue.use(require('vue-moment'));

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import XEUtils from 'xe-utils'
import VXETable from 'vxe-table'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import 'vxe-table/lib/style.css'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { locale });

import moment           from 'moment-timezone'
import 'moment/locale/ru'
moment.tz.setDefault('Europe/Moscow')
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment

VXETable.formats.mixin({
    format_date ({ cellValue }) {
        if (!cellValue) return '';
        let vl;
        if (typeof(cellValue) === 'string' && cellValue.length == 10) {
            vl = moment(cellValue)
        }
        else {
            vl = moment(cellValue).utcOffset('-0000')
        }

        return vl.format('DD.MM.YY')
    },
    format_month ({ cellValue }) {
        if (!cellValue) return '';
        let vl;
        if (typeof(cellValue) === 'string' && cellValue.length == 10) {
            vl = moment(cellValue)
        }
        else {
            vl = moment(cellValue).utcOffset('-0000')
        }

        return vl.format('MM.YY')
    },
    format_datetime ({ cellValue }) {
        if (!cellValue) return '';

        let vl;
        if (typeof(cellValue) === 'string' && cellValue.length == 10) {
            vl = moment(cellValue)
        }
        else {
            vl = moment(cellValue).utcOffset('-0000')
        }
        if (vl.format('HH:mm') === '00:00')
            return vl.format('DD.MM.YY');
        return vl.format('DD.MM.YY HH:mm')
    },
    no_zero_int ({ cellValue }) {
        return (cellValue) ? cellValue : null
    },
    format_bool ({ cellValue }) {
        return (cellValue) ? "да" : "нет"
    },
})
Vue.use(VXETable, {
    i18n: key => XEUtils.get(enUS, key)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
