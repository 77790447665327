import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueJwtDecode from 'vue-jwt-decode'
import Cookies from 'js-cookie'
import { host } from '@/../config/host.js'

function getAuthToken () {
    let accessToken  = localStorage.cv_access;
    let refreshToken = localStorage.cv_refresh;
    
    if (accessToken && accessToken !== 'undefined' && refreshToken && refreshToken !== 'undefined' && VueJwtDecode.decode(accessToken).exp <= (Date.now()  / 1000).toFixed(0)) {
        if (process.env.NODE_ENV === 'development') console.log('token should be updated');
        let authTokenRequest = axios.post(`${host}/refresh-token/`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + refreshToken
                }
            })
            .then(response => {
                localStorage.cv_access = response.data.access_token;
                return response.data.access_token
            })
            .catch(error => {
                if (process.env.NODE_ENV === 'development') console.log(error.request);
                localStorage.removeItem('cv_access');
                localStorage.removeItem('cv_refresh');
            })
        return authTokenRequest
    }
    return accessToken
}

axios.defaults.timeout = 180000;

axios.interceptors.request.use(async function (config) {
    if (config.url !== `${host}/login/` && config.url !== `${host}/refresh-token/`) {
        let token = await getAuthToken()
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (config) {
    return config
}, function (error) {
    if (error.request !== undefined && (error.request.responseURL === /refresh-token/ || error.request.status === 401 && error.config.__isRetryRequest)) {
        localStorage.removeItem('cv_access');
        localStorage.removeItem('cv_refresh');
        return Promise.reject(error)
    }
    else if (error.request !== undefined && error.request.status === 401) {
        error.config.__isRetryRequest = true
        return axios.request(error.config)
    }

    return Promise.reject(error)
})

Vue.use(VueAxios, axios)

const state = {
    is_authed : false,
    user      : {},
}

const getters = {
    admin: state => {
        return (state.user.role && state.user.role == 1) ? true : false
    },
    viewer: state => {
        return (state.user.role && state.user.role == 2) ? true : false
    },
}

const actions = {
    check_auth({commit, dispatch}){
        let has_token = (localStorage.cv_access) ? true : false;
        commit('set', {type: 'is_authed', items: has_token});
        if (has_token) dispatch('get_current_user')
    },

    get_current_user({commit, dispatch}){
        // commit('set', {type: 'user', items: {}});

        return Vue.axios.post(`${host}/check-token/`).then((response) => {
            let body = response.data
            // body.id = body._id['$oid']
            commit('set', {type: 'user', items:body})
        })
        .catch(error => {
            commit('set', {type: 'user', items: {}});
            dispatch('process_error', error)
        })
    },

    login({commit, dispatch}, data){
        commit('set', {type: 'is_authed', items: false});

        Vue.axios.post(`${host}/login/`, data).then(response => {
            let body = response.data
            if ('access_token' in body && body.access_token) {
                localStorage.cv_access = body.access_token;
                localStorage.cv_refresh = body.refresh_token;
                commit('set', {type: 'is_authed', items: true});
                dispatch('get_current_user')
                router.push({ path: '/' }).catch(()=>{});
            }
        })
        .catch(error => {
            dispatch('process_error', error)
        })
    },

    process_error({commit}, error){
        if (process.env.NODE_ENV === 'development') {
            if (error.request) console.log(error.request);
            else console.log(error);
        }
        if (error.request && error.request.status) {
            if (error.request.status == 401) {
                localStorage.removeItem('cv_access');
                localStorage.removeItem('cv_refresh');
                commit('set', {type: 'is_authed', items: false});
            }
            else if (error.request.status == 422) {
                let msg = JSON.parse(error.request.responseText)
                if (msg.msg === 'Not enough segments' || msg.message === 'Not enough segments') {
                    localStorage.removeItem('cv_access');
                    localStorage.removeItem('cv_refresh');
                    commit('set', {type: 'is_authed', items: false});
                }
                else if (error.request.responseText) {
                    let msg = JSON.parse(error.request.responseText)
                    // Message({
                    //     message: error.request.status + ' ' + msg.message,
                    //     type: 'error',
                    //     dangerouslyUseHTMLString: true,
                    // })
                }
            }
            else if (error.request.responseText) {
                let msg = JSON.parse(error.request.responseText)
                // Message({
                //     message: error.request.status + ' ' + msg.message,
                //     type: 'error',
                //     dangerouslyUseHTMLString: true,
                // })
            }
        }
        else {
            // Message({
            //     message: 'Серверная ошибка',
            //     type: 'error'
            // })
        }
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
