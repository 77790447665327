<template>
    <div class="login-page">
        <vxe-form :data="login_form" @submit="make_login" :rules="login_rules">
            <vxe-form-item title="Логин" field="login" :item-render="{}">
                <template #default="{ data }">
                    <vxe-input v-model="data.login" placeholder=""></vxe-input>
                </template>
            </vxe-form-item>

            <vxe-form-item title="Пароль" field="password" :item-render="{}">
                <template #default="{ data }">
                    <vxe-input v-model="data.password" type="password" placeholder=""></vxe-input>
                </template>
            </vxe-form-item>
          
            <vxe-form-item>
                <template #default>
                    <vxe-button type="submit" status="primary">Войти</vxe-button>
                </template>
            </vxe-form-item>
        </vxe-form>
    </div>
</template>

<script>
export default {
    data () {
        return {
            login_form: {
                login: '',
                password: '',
            },
            login_rules: {
                login: [{ required: true }],
                password: [{ required: true }],
            }
        }
    },

    methods: {
        make_login(){
            this.$store.dispatch('app/login', this.$data.login_form).then(() => {
                this.login_form = {
                    username: '',
                    password: '',
                }
            })
        }
    }
}
</script>

<style scoped>
    .login-page {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
