import Vue  from 'vue'
import { host } from '@/../config/host.js'

const state = {}

const actions = {
    get_data({dispatch, commit}, params){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/timesheet/`, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    save_data({dispatch, commit}, data) {
        return new Promise((resolve, reject) => {
            Vue.axios.post(`${host}/timesheet/`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },

    fill_report({dispatch, commit}, params) {
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/timesheet/fill-report/`, {params: params})
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },
}

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
