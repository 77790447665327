import Vue            from 'vue'
import Router         from 'vue-router'

Vue.use(Router)

export const routerMap = [
    {
        path      : '/',
        name      : 'dashboard',
        component : () => import('@/views/Versions'),
    },
    {
        path      : '/bitbucket',
        name      : 'bitbucket',
        component : () => import('@/views/Bitbucket'),
    },
    {
        path      : '/tasks',
        name      : 'tasks',
        component : () => import('@/views/Tasks'),
    },
    {
        path      : '/report',
        name      : 'report',
        component : () => import('@/views/Report'),
    },
    {
        path      : '/timesheet',
        name      : 'timesheet',
        component : () => import('@/views/Timesheet'),
    },
]


export default new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: routerMap,
    mode: 'history'
})
