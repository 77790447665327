import Vue  from 'vue'
import { host } from '@/../config/host.js'

const state = {
    options_list: []
}

const actions = {
    get_list({dispatch, commit}){
        return new Promise((resolve, reject) => {
            Vue.axios.get(`${host}/tasks/`)
            .then((response) => {
                resolve(response.data);
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
                reject(error);
            })
        }, error => {
            dispatch('app/process_error', error, { root: true })
            reject(error);
        })
    },


    update_task({dispatch, commit}, data) {
        return Vue.axios.post(`${host}/tasks/${data.id}/`, data)
            .then((response) => {
                return
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    add_task({dispatch, commit}, data) {
        return Vue.axios.post(`${host}/tasks/`, data)
            .then((response) => {
                return
            })
            .catch(error => {
                dispatch('app/process_error', error, { root: true })
            })
    },

    get_options({dispatch, commit}){
        return Vue.axios.get(`${host}/tasks/options/`)
        .then((response) => {
            commit('set', {type: 'options_list', items:response.data})
        })
        .catch(error => {
            dispatch('app/process_error', error, { root: true })
        })
    },
}   

const mutations = {
    set(state, {type, items}) {
        state[type] = items
    },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
